$use_maison: true;
@import 'styles/mixins';
@import 'styles/fonts';
@import 'styles/colors';
@import 'styles/spacers';

.navbar__link {
  cursor: pointer;
  display: block;

  @include breakpoint(desktop) {
    padding: 0;
  }

  svg,
  svg path {
    color: $black;
    width: $spacer-s;
  }
}

.cart-icon__count {
  @include font($body-xsmall-bold);

  align-items: center;
  background-color: $black;
  border: 1px solid $white;
  border-radius: 100%;
  border-radius: 10px;
  color: $white;
  display: flex;
  height: 12px;
  justify-content: center;
  line-height: 1em;
  padding: 0 1px;
  position: absolute;
  right: 0;
  top: -9px;
  width: 10px;

  &--large {
    border-radius: 25px;
    right: -2px;
    top: -14px;
    width: 25px
  }
}